import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import {useExportBalance} from "./useExportBalance";

const ExportButton = ({ filename = "balance.xlsx", label = "Exportar", filters }) => {
  const { getExportData, data, loading, setData } = useExportBalance();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!loading && data && Object.keys(data).length > 0) {
      handleExport();
      setOpenModal(false); // Cierra el diálogo después de la carga
    }
  }, [data, loading]);
  
  const handleExport = () => {

    if (!data ) return;
    
    const ingresosTotal = data.ingresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);
    const otrosIngresosTotal = data.otrosIngresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);
    const egresosTotal = data.egresosData.reduce((total, record) => total + parseFloat(record.Comisiones || 0), 0);
    const otrosEgresosTotal = data.otrosEgresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);    

    const totalIngresos = ingresosTotal + otrosIngresosTotal;
    const totalEgresos = egresosTotal + otrosEgresosTotal;
    const balanceFinal = totalIngresos - totalEgresos;
    
    const workbook = XLSX.utils.book_new();
    
    const ingresosSheet = XLSX.utils.json_to_sheet(data.ingresosData);
    const otrosIngresosSheet = XLSX.utils.json_to_sheet(data.otrosIngresosData);
    const egresosSheet = XLSX.utils.json_to_sheet(data.egresosData);
    const otrosEgresosSheet = XLSX.utils.json_to_sheet(data.otrosEgresosData);
    
    const summaryData = [
      { Tipo: "Total Ingresos", Monto: totalIngresos.toFixed(2) },
      { Tipo: "Total Egresos", Monto: totalEgresos.toFixed(2) },
      { Tipo: "Balance Final", Monto: balanceFinal.toFixed(2) },
    ];
    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    
    XLSX.utils.book_append_sheet(workbook, ingresosSheet, "Ingresos por Servicios");
    XLSX.utils.book_append_sheet(workbook, otrosIngresosSheet, "Otros Ingresos");
    XLSX.utils.book_append_sheet(workbook, egresosSheet, "Egresos por Comisiones");
    XLSX.utils.book_append_sheet(workbook, otrosEgresosSheet, "Otros Egresos");
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Resumen");
    
    [ingresosSheet, otrosIngresosSheet, egresosSheet, otrosEgresosSheet, summarySheet].forEach((sheet) => {
      sheet["!cols"] = [{ wch: 15 }, { wch: 20 }, { wch: 30 }, { wch: 25 }, { wch: 15 }];
    });
    
    XLSX.writeFile(workbook, filename);

    setData([]);
  };

  const handleClick = async () => {
    if (Object.keys(data).length === 0) {
      setOpenModal(true);
      await getExportData(filters);
    } else {
      handleExport();
    }
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<DownloadIcon />} disabled={loading} style={{ fontSize: 12 }}>
        {loading ? <CircularProgress size={24} /> : label}
      </Button>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
          <Typography style={{ marginTop: 20 }}>Cargando datos para exportación...</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

ExportButton.propTypes = {
  filename: PropTypes.string,
};

export default ExportButton;
