import React, { memo, useState } from "react";
import {
  SelectInput,
  List as BaseList,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  TopToolbar,
  FilterButton,
  CreateButton,
  usePermissions,
  useNotify,
  useDataProvider,
  SimpleForm,
  FunctionField,
  useListContext,
} from "react-admin";
import Firebase from "../Firebase/Firebase";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import ExportButton from "../components/ExcelExportButton";
import useExport from "../components/UseExcelExport";
import moment from "moment/moment";

const paymentMethods = [
  { id: "Efectivo", name: "Efectivo" },
  { id: "Tarjeta", name: "Tarjeta" },
  { id: "QR", name: "QR" },
  { id: "Tigo Money", name: "Tigo Money" },
];

const status = (record) => {
  if (record.status === "Pendiente de cotizacion") {
    return [
        { id: "Pendiente de cotizacion", name: "Pendiente de cotización" },
        { id: "Confirmado", name: "Enviar cotización y confirmar" },
        // { id: "Pendiente", name: "Pendiente" },
        // { id: "Confirmado", name: "Confirmado" },
        // { id: "En proceso", name: "En proceso" },
        // { id: "Finalizado", name: "Finalizado" },
        { id: "Cancelada por el proveedor", name: "Cancelado" },
    ];
  }
  if (record.status === "Cotizacion enviada") {
    return [
      { id: "Cotizacion enviada", name: "Cotización enviada" },
    ];
  }
  if (record.status === "Pendiente") {
    return [
      { id: "Pendiente", name: "Pendiente" },
      { id: "Confirmado", name: "Confirmar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (record.status === "Confirmado") {
    return [
      { id: "Confirmado", name: "Confirmado" },
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (record.status === "En proceso") {
    return [
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizar" },
      { id: "Cancelada por el proveedor", name: "Cancelar" },
    ];
  }
  if (record.status === "Finalizado") {
    return [
      { id: "Finalizado", name: "Finalizado" },
    ];
  }
  if (record.status === "Cancelada por el proveedor") {
    return [
      { id: "Cancelada por el proveedor", name: "Cancelado por el proveedor" },
    ];
  }
  if (record.status === "Cancelada por el cliente") {
    return [
      { id: "Cancelada por el cliente", name: "Cancelado por el cliente" },
    ];
  }
};

const BookingFilter = [
  <SelectInput
    label="Estado"
    source="status"
    choices={[
      { id: "Pendiente de cotizacion", name: "Pendiente de cotización" },
      { id: "Cotizacion enviada", name: "Cotización enviada" },
      { id: "Pendiente", name: "Pendiente" },
      { id: "Confirmado", name: "Confirmado" },
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizado" },
      { id: "Cancelada por el proveedor", name: "Cancelada por el proveedor" },
      { id: "Cancelada por el cliente", name: "Cancelada por el cliente" },
    ]}
  />,
  <DateInput label="Desde" source="bookingDate>" />,
  <DateInput label="Hasta" source="bookingDate<" />,
];

const ListActions = (props) => {
  const { getExportData, exportData, setExportData } = useExport("booking");
  const [openModal, setOpenModal] = useState(false);
  const { filterValues } = useListContext();

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData(filterValues);
      setOpenModal(false);
    }
  };

  const handleClearData = () => {
    setExportData([]);
  };

  const columns = [
    { label: "ID", source: "_id" },
    { label: "Fecha de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('YYYY-MM-DD') }, 
    { label: "Hora de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('HH:mm') },
    { label: "Fecha de la reserva", source: "bookingDate", transform: (val) => moment(val).format('YYYY-MM-DD') },
    { label: "Hora de la reserva", source: "bookingDate", transform: (val) => moment(val).format('HH:mm') }, 
    { label: "Nombre del cliente", source: "customer.fullName" },
    { label: "Teléfono del cliente", source: "customer.phone" },
    { label: "Fecha de nacimiento", source: "customer.birthdate", transform: (val) => moment(val).format('YYYY-MM-DD') },
    { label: "Género", source: "customer.gender" },
    { label: "Tipo de descuento", source: "paymentInfo.coupon.discountType" },
    { label: "Descuento", source: "paymentInfo.coupon.discount" },

    {
      label: "Dirección del cliente",
      source: "customer.address",
      transform: (address) => {
        if (!address || !address.street || !address.house || address.reference === undefined) {
          return "";
        }
        return `${address.street} #${address.house}, ${address.reference}`;
      }
    },
    { label: "Método de Reserva", source: "createdFrom" },
    { label: "Servicio en Sucursal", source: "isInBranch", transform: (isInBranch) => isInBranch ? "Si" : "No" },
    { label: "Servicios Solicitados", source: "serviceCart", transform: (services) => services.map((service) => service.service.name).join(", ") },
    { label: "Cantidad de Servicios Solicitados", source: "serviceCart", transform: (services) => services.length },
    { label: "Precio Total", source: "paymentInfo.totalPrice" },
    { label: "Cupón de descuento", source: "coupon.name" },
    { label: "Notas", source: "notes" },
    { label: "Empleado", source: "employee.fullName" },
    { label: "Método de Pago", source: "paymentInfo.paymentMethod" },
    { label: "Estado de Reserva", source: "status" },
    { label: "Estado de Pago", source: "paymentInfo.status" },
    { label: "Sucursal", source: "branch.name" },
    { label: "Calificación", source: "feedbackInfo.rating" },
    { label: "Comentario", source: "feedbackInfo.comment" },
  ];

  return (
    <TopToolbar
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={props.filters} />
        <CreateButton basePath={props.basePath} />
      </div>
      <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="bookings.xlsx" clearData={handleClearData} />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => {
  
  const { permissions } = usePermissions();  

  return (
    <Firebase>
      <BaseList sort={{ field: "bookingDate", order: "DESC" }} filters={BookingFilter} {...props} actions={<ListActions filters={BookingFilter} />}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="_id" label="ID" />
          <DateField source="bookingDate" label="Fecha de Inicio" showTime locales="es-ES" />
          <ArrayField source="serviceCart" label="Servicios Solicitados">
            <SingleFieldList>
              <ChipField source="service.name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="paymentInfo.coupon.name" label="Cupón" />
          <TextField source="createdFrom" label="Método" />
          <TextField source="paymentInfo.totalPrice" label="Total (Bs.)" />
          <FunctionField
            source="paymentInfo.paymentMethod"
            label="Método de Pago"
            render={(record) => (
              <div onClick={(e) => e.stopPropagation()}>
                {" "}
                <PaymentStatusField record={record} />
              </div>
            )}
          />
          <FunctionField
            source="status"
            label="Estado de Reserva"
            render={(record) => (
              <div onClick={(e) => e.stopPropagation()}>
                {" "}
                <BookingStatus record={record} />
              </div>
            )}
          />
          <TextField source="paymentInfo.status" label="Estado de Pago" />
          <TextField source="feedbackInfo.rating" label="Rating" />
          <BooleanField source="isInBranch" label="¿Servicio en Sucursal?" />
          {permissions !== "admin-sucursal" && <TextField source="branch.name" label="Sucursal" />}
        </Datagrid>
      </BaseList>
    </Firebase>
  );};

const PaymentStatusField = memo(({ record }) => {  
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();  
  
  const handleChange = async (newStatus) => {
    setLoading(true);
    try {
      await dataProvider.update("booking", {
        id: record._id,
        data: { paymentMethod: newStatus },
        meta: { customAction: "updatePaymentMethod" },
      });
      
      notify("Actualizado correctamente", { type: "success" });
    } catch (error) {
      console.error("Error al actualizar estado de pago:", error);
      notify("Error al actualizar", { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <SimpleForm toolbar={false}>
      <SelectInput
        source="paymentInfo.paymentMethod"
        label="Método de Pago"
        choices={paymentMethods}
        disabled={loading}
        onChange={(e) => {
          console.log("e.target.value", e.target.value);
          return handleChange(e.target.value);
        }}
        style={{ margin: 0, padding: 0 }}
      />
    </SimpleForm>
  );
}); 

const BookingStatus = memo(({ record }) => {
  const [ loading, setLoading ] = useState(false);
  const [choices, setChoices] = useState(status(record));
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = async (newStatus) => {
    setLoading(true);
    try {
      await dataProvider.update("booking", {
        id: record._id,
        data: { status: newStatus },
        meta: { customAction: "updateStatus" },
      });
      
      setChoices(status({ ...record, status: newStatus }));
      
      notify("Actualizado correctamente", { type: "success" });
    } catch (error) {
      console.error("Error al actualizar estado de pago:", error);
      notify("Error al actualizar", { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <SimpleForm toolbar={false}>
      <SelectInput
        source="status"
        label="Estado de Reserva"
        choices={choices}
        disabled={loading}
        onChange={(e) => {
          console.log("e.target.value", e.target.value);
          return handleChange(e.target.value);
        }}
        style={{ margin: 0, padding: 0 }}
      />
    </SimpleForm>
  );
});