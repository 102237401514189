import React, { useState, useEffect, useRef } from "react";
import {
  useListContext,
  ListBase,
  Link,
  useGetList,
  usePermissions,
  required,
  SelectInput,
  useCreate,
  useGetOne,
  SimpleForm,
  Toolbar,
  SaveButton,
  useUpdate,
  useNotify,
  useRedirect,
} from "react-admin";
import { DateInput } from "../components/DatePicker";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  Button,
  ButtonBase,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Box,
} from "@mui/material";
import { es } from "date-fns/esm/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import Firebase from "../Firebase/Firebase";
import moment from "moment";
import { useFormContext } from "react-hook-form";

export const ScheduleList = (props) => {
  const [date, setDate] = useState(new Date().toISOString());
  console.log(date)
  const [timeUnit, setTimeUnit] = useState("day");
  const [serviceMethod, setServiceMethod] = useState("Ambos");
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [branch, setBranch] = useState(profile?.branch);
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissions();
  useEffect(() => {
    if (searchParams.get("date")) {
      setDate(new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (searchParams.get("branch")) {
      setBranch(searchParams.get("branch"));
    }
  }, [searchParams.get("branch")]);

  useEffect(() => {
    if (searchParams.get("serviceMethod")) {
      setServiceMethod(searchParams.get("serviceMethod"));
    }
  }, [searchParams.get("serviceMethod")]);

  return (
    <Firebase>
      <ListBase
        {...props}
        filter={{
          date: date,
          timeUnit: timeUnit,
          serviceMethod: serviceMethod,
          branch: branch,
        }}
      >
        <br />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Método</InputLabel>
          <Select
            style={{ width: 200 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={serviceMethod}
            label="Método"
            onChange={(e) => {
              setServiceMethod(e.target.value);
              if (e.target.value === "A domicilio") {
                setBranch(null);
              }
            }}
          >
            <MenuItem value="Ambos">Ambos</MenuItem>
            <MenuItem value="En sucursal">En sucursal</MenuItem>
            <MenuItem value="A domicilio">A domicilio</MenuItem>
          </Select>
        </FormControl>
        <br />
        { permissions !== "admin-sucursal" && <SelectBranchInput
          serviceMethod={serviceMethod}
          branch={branch}
          setBranch={setBranch}
        /> 
        }
        <Schedule
          date={date}
          serviceMethod={serviceMethod}
          branch={branch}
          setDate={setDate}
          setTimeUnit={setTimeUnit}
        />
      </ListBase>
    </Firebase>
  );
};

const CustomEditor = ({ scheduler, serviceMethod, branch }) => {
  const navigate = useNavigate();
  return (
    <DialogContent>
      <Typography>
        Hora: {scheduler?.state?.start?.value.toLocaleTimeString("es-ES")}
      </Typography>
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/booking/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&serviceMethod=${serviceMethod}&branch=${branch}&redirect=schedule`
          )
        }
        fullWidth
      >
        Crear Reserva
      </Button>
      <br />
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/booking/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&serviceMethod=${serviceMethod}&branch=${branch}&redirect=schedule&type=sobreHorario`
          )
        }
        fullWidth
      >
        Sobre Horario
      </Button>
      <br />
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/unavailability/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&redirect=schedule`
          )
        }
        fullWidth
      >
        Horario No Disponible
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={scheduler.close} fullWidth>
        Cancelar
      </Button>
    </DialogContent>
  );
};

const HourInput = ({ event, branch }) => {
  const { watch, setValue } = useFormContext();
  const date = watch("date");
  const [hourPicker, setHourPicker] = useState([]);
  console.log(branch)
  const editedEstimatedWorkMinutes = watch("editedEstimatedWorkMinutes");
  const totalEstimatedWorkMinutes =
  editedEstimatedWorkMinutes ??
  event.event.services?.reduce(
    (a, b) =>
      a?.unitEstimatedWorkMinutes * a?.quantity ||
      a + b?.unitEstimatedWorkMinutes * b?.quantity,
    0
  ) ??
  0;
  useEffect(() => {
    _setHourPicker(moment(date));
    setValue("hour", "");
  }, [date, totalEstimatedWorkMinutes]);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(event)
  useEffect(() => {
    if (searchParams.get("date")) {
      setValue("date", new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  const [fetchAvailability, { isLoading, error }] = useCreate();

  const _setHourPicker = async (date) => {
    let array = [];
    let today = moment();
    let isSameDay = moment(today).isSame(date, "day");
    await fetchAvailability(
      "dateAvailability/" + JSON.parse(localStorage.getItem("profile"))._id,
      {
        data: {
          date: date,
          estimatedTime: event.event.services[0].estimatedWorkMinutes,
          isInBranch: event.event.isInBranch,
          branch: event.event.branch._id,
          employee: event.event.admin_id,
          serviceCart: event.event.services?.map((e) => e.service._id) ?? [],
        },
      },
      {
        onSuccess: (response) => {
          response.data.availability.map((e) => {
            const _date = moment(e);
            if (isSameDay && _date.hours() <= today.hours()) {
              // return;
            }
            array.push({
              id: _date.hours() + _date.minutes() / 60,
              name: _date.format("HH:mm"),
            });
          });
          setHourPicker(array);
        },
      }
    );
  };

  return (
    <SelectInput
      validate={[required()]}
      source="hour"
      label="Hora"
      choices={hourPicker}
      fullWidth
      disabled={hourPicker.length === 0 }
    />
  );
};

const AvailableDateInput = ({ availability, ...props }) => {
  console.log(availability)
  return (
    <DateInput
      validate={[required()]}
      source="date"
      label="Fecha de reserva"
      fullWidth
      inputVariant="outlined"
      options={{
        format: "dd/MM/yyyy",
        clearable: true,
        disablePast: true,
      }}
      providerOptions={{ locale: es }}
      shouldDisableDate={(day) => {
        const date = moment(day);
        return (
          date < moment().startOf("day") ||
          availability[date.isoWeekday() - 1].length === 0
        );
      }}
      {...props}
    />
  );
};

const DialogDate = ({ scheduler, serviceMethod, branch, handleClose, setDate }) => {
  const [update, { loading }] = useUpdate();
  const notify = useNotify(); 
  const initAvailability = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [availability, setAvailability] = useState(initAvailability);
  const {
    data: user,
  } = useGetOne("availability", {
    id: scheduler?.event.admin_id ?? JSON.parse(localStorage.getItem("profile"))._id,
    meta: { branch: branch ? branch?._id : null },
  });

  useEffect(() => {
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);
  const handleUpdate = (data) => {
    const { date, hour } = data;

    update(
      "booking",
      {
        id: scheduler.event.event_id,
        data: { date, hour },
        meta: { customAction: "updateDate" }, 
      },
      {
        onSuccess: (updatedData) => {
          notify("Fecha de la reserva actualizada con éxito", "success");
          handleClose(); 
          setDate(moment(date).toISOString());
          // redirect(`/schedule?date=${updatedData.bookingDate}`); // Recarga la vista de reservas
        },
        onFailure: (error) => notify(`Error al actualizar: ${error.message}`, "warning"),
      }
    );
  };
  return (
    <DialogContent>
      <Typography mb={2}>
        Seleccione la nueva fecha y hora:
      </Typography>
      <SimpleForm sx={{ padding: 0 }} toolbar={false} onSubmit={handleUpdate}>
        <AvailableDateInput availability={availability} />
        <br />
        <HourInput event={scheduler} branch={branch} />
        <SaveButton fullWidth disabled={loading} />
      </SimpleForm>
      <Button variant="contained" color="secondary" onClick={handleClose} fullWidth>
        Cancelar
      </Button>
    </DialogContent>
  );
};

const Schedule = ({ date, setDate, setTimeUnit, serviceMethod }) => {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [branch, setBranch] = useState(profile?.branch);
  const { data } = useListContext();
  const calendarRef = useRef(null);
  useEffect(() => {
    if (Array.isArray(data)) {
      const filteredEvents = data[0].events.filter(event => event.status !== "Cancelada por el proveedor");
      calendarRef.current.scheduler.handleState(data[0].resources, "resources");
      calendarRef.current.scheduler.handleState(
        filteredEvents.map((e) => ({
          ...e,
          start: new Date(e.start),
          end: new Date(e.end),
        })),
        // data[0].events.map((e) => {
        //   return {
        //     ...e,
        //     start: new Date(e.start),
        //     end: new Date(e.end),
        //   };
        // }),
        "events"
      );
    }
  }, [data, date]);

  console.log(date)
  const [searchParams, setSearchParams] = useSearchParams();
  let initialDate = new Date();
  if (searchParams.get("date")) {
    initialDate = new Date(searchParams.get("date"));
  }
  return (
    <Scheduler
      key={date}
      ref={calendarRef}
      view="day"
      events={[]}
      resources={[]}
      resourceFields={{
        idField: "admin_id",
        textField: "fullName",
        subTextField: "phone",
        avatarField: "fullName",
        colorField: "color",
      }}
      day={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.start || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;
          }
          const cellStart = moment(props.start);
          const cellHour = cellStart.hour();
          const cellDayIndex = cellStart.isoWeekday() - 1;
        
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
          
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#FFFFFF" }} />;
          }
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
          // console.log(employee)
          if (employee.admin_id === 0) {
            console.log('employee not found')
          }

          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#FFFFFF" }} />;
          }
        
          let isAvailable = false;
          if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            // isAvailable = true;
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotStartHour = slot.startHour;
              const slotEndHour = slot.endHour;
              const slotDayIndex = slot.dayIndex; // Día de la semana en índice
        
              if (cellDayIndex !== slotDayIndex) {
                return false;
              }
              return cellHour >= slotStartHour && cellHour < slotEndHour;
            });
          }
        
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", // 🔹 Azul si disponible, gris si no
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", // 🔹 Transición suave
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; // 🔹 Azul claro si disponible, gris más oscuro si no
                e.target.style.border = isAvailable ? "" : "1px solid #808080"; // 🔹 Borde más fuerte
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; // 🔹 Volver al color original
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; // 🔹 Volver al borde original
              }}
            />
          );
        },
      }}
      week={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.start || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;;
          }
      
          const cellStart = moment(props.start);
          const cellHour = cellStart.hour();
          const cellDayIndex = cellStart.isoWeekday() - 1; // 🔹 Lunes = 0, Martes = 1, etc.
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
      
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
      
          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          let isAvailable = false;
          if (props.admin_id === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true;
          } else if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true; 
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotStartHour = slot.startHour;
              const slotEndHour = slot.endHour;
              const slotDayIndex = slot.dayIndex;
      
              if (cellDayIndex !== slotDayIndex) {
                return false;
              }
              return cellHour >= slotStartHour && cellHour < slotEndHour;
            });
          }
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", // 🔹 Azul si disponible, gris si no
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", // 🔹 Transición suave
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; // 🔹 Azul claro si disponible, gris más oscuro si no
                e.target.style.border = isAvailable ? "" : "1px solid #808080"; // 🔹 Borde más fuerte
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; // 🔹 Volver al color original
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; // 🔹 Volver al borde original
              }}
            />
          );
        },
      }}
      month={{
        navigation: true,
        cellRenderer: (props) => {
          if (!props || !props.day || !props.admin_id) {
            return  <div
              onClick={props.onClick}
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0F8FF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#FFFFFF";
              }}
            />;;
          }
          const cellDayIndex = moment(props.end).isoWeekday() - 1; 
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { data } = useListContext();
      
          if (!Array.isArray(data) || !data[0]?.resources) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          const employee = data[0].resources.find(
            (resource) => resource.admin_id === props.admin_id
          );
      
          if (!employee) {
            return <div style={{ height: "100%", backgroundColor: "#D3D3D3" }} />;
          }
      
          let isAvailable = false;
          if (!Array.isArray(employee.availability) || employee.availability.length === 0) {
            isAvailable = cellDayIndex >= 0 && cellDayIndex <= 5;   // de lunes a sabado
            // isAvailable = true; 
          } else {
            isAvailable = employee.availability.some((slot) => {
              const slotDayIndex = slot.dayIndex; 
              return cellDayIndex === slotDayIndex;
            });
          }
      
          return (
            <div
              onClick={props.onClick}
              style={{
                backgroundColor: isAvailable ? "#FFFFFF" : "#D3D3D3",
                height: "100%",
                border: isAvailable ? "" : "1px solid #A9A9A9", 
                cursor: "pointer",
                transition: "background-color 0.3s ease, border 0.3s ease", 
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#F0F8FF" : "#B0B0B0"; 
                e.target.style.border = isAvailable ? "" : "1px solid #808080";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = isAvailable ? "#FFFFFF" : "#D3D3D3"; 
                e.target.style.border = isAvailable ? "" : "1px solid #A9A9A9"; 
              }}
            />
          );
        },
      }}
      
      translations={{
        navigation: {
          month: "Mes",
          week: "Semana",
          day: "Día",
          today: "Hoy",
        },
        form: {
          addTitle: "Crear Reserva",
          editTitle: "Ver Reserva",
          confirm: "Confirmar",
          delete: "Borrar",
          cancel: "Cancelar",
        },
        event: {
          title: "Título",
          start: "Inicio",
          end: "Final",
          allDay: "Todo el día",
        },
        moreEvents: "Más...",
        loading: "Cargando...",
      }}
      locale={es}
      selectedDate={new Date(date)}
      onSelectedDateChange={(d) => setDate(d.toISOString())}
      onViewChange={(v) => setTimeUnit(v)}
      deletable={false}
      draggable={false}
      editable={false}
      customEditor={(scheduler) => (
        <CustomEditor
          scheduler={scheduler}
          branch={branch}
          serviceMethod={serviceMethod}
        />
      )}
      eventRenderer={({ event, onClick }) => (
        <ButtonBase onClick={onClick}>
          <div
            style={{
              padding: "2px 6px",
              backgroundColor: event.type === "unavailability" ? "#D3D3D3" : event.color, // Color gris para "No disponible"
              height: "100%",
              // border: event.type === "unavailability" ? "1px solid #A9A9A9" : "none", // Opcional: borde para distinguir
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
              {event.title}
            </Typography>
            <Typography style={{ fontSize: 11 }} noWrap>
              {format(event.start, `HH:mm`, {
                locale: es,
              })}{" "}
              -
              {format(event.end, `HH:mm`, {
                locale: es,
              })}
            </Typography>
            {event.type === "booking" && (
              <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
                {event.status}
              </Typography>
            )}
          </div>
        </ButtonBase>
      )}
      viewerExtraComponent={(fields, event) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [open, setOpen] = useState(false); // Estado para el modal
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [update, { loading }] = useUpdate();
        console.log(event)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const notify = useNotify();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { refetch } = useListContext();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate();
        const cancel = () =>
        update(
          "booking",
          {
            id: event.event_id,
            data: {},
            meta: { customAction: "toCancelledByProvider" },
          },
          {
            onSuccess: (data) => {
              const redirectParam = searchParams.get("redirect")
                ? searchParams.get("redirect") + "?date=" + data.bookingDate
                : null;
              notify("Reserva cancelada con éxito");
              refetch();
            },
            onFailure: (error) =>
              notify(`Error al cancelar reserva: ${error}`, "warning"),
          }
        );
        const handleOpen = () => setOpen(true); 
        const handleClose = () => setOpen(false);
        return (
          <div>
            <Typography>ID: {event.event_id}</Typography>
            <Typography>
              Celular:{" "}
              {event.phone ? (
                <MuiLink href={`https://wa.me/${event.phone}`} target="_blank" rel="noreferrer" color="primary">
                  {event.phone}
                </MuiLink>
              ) : (
                "Sin número"
              )}
            </Typography>
            <Typography>Estado: {event.status}</Typography>
            <Box
              sx={{
                display: "flex",
                gap: 0.5, // 🔹 Menos espacio entre los botones
                mt: 0.5,  // 🔹 Menos margen superior
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleOpen}
                disabled={event.remainingDateChanges <= 0 || event.type === "unavailability"}
                sx={{
                  flex: 1,
                  minWidth: "90px", // 🔹 Más pequeño
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px", 
                  fontSize: "12px", 
                  color: "white"
                }}
              >
                Modificar <br /> Fecha
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(`/${event.type}/${event.event_id}/show?redirect=schedule`)}
                sx={{
                  flex: 1,
                  minWidth: "90px",
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px",
                  fontSize: "12px",
                }}
              >
                Más <br /> Información
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={cancel}
                disabled={event.type === "unavailability"}
                sx={{
                  flex: 1,
                  minWidth: "90px",
                  maxWidth: "110px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  padding: "4px",
                  fontSize: "12px",
                }}
              >
                Cancelar <br /> Reserva
              </Button>
            </Box>


            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Modificar Fecha de Reserva</DialogTitle>
              <DialogDate scheduler={{ event }} branch={branch} handleClose={handleClose} setDate={setDate}/>
            </Dialog>
          </div>
        );
      }}
    />
  );
};

const SelectBranchInput = ({ serviceMethod, branch, setBranch }) => {
  const { data, loading, error } = useGetList("enterprise/branches", {
    filter: {
      status: true,
    },
  });

  return serviceMethod !== "A domicilio" ? (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
      <Select
        style={{ width: 200 }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={branch}
        label="Método"
        onChange={(e) => setBranch(e.target.value)}
      >
        {data?.map((e) => {
          return (
            <MenuItem key={e._id} value={e._id}>
              {e.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : (
    <React.Fragment />
  );
};
