import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Datagrid,
  TextField,
  Show as BaseShow,
  ArrayField,
  DateField,
  Tab,
  TabbedShowLayout,
  Error,
  Loading,
  FunctionField,
  Button,
  NumberField,
  useUpdate,
  useGetList,
  useNotify,
  useRedirect,
  useShowController,
  BooleanField,
  NumberInput,
  Edit,
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  FormDataConsumer,
  TextInput,
  useEditController,
  useEditContext,
  useRecordContext,
  SaveButton,
} from "react-admin";
import { toHoursAndMinutes } from "../util";
import {
  MenuItem,
  FormControl,
  Box,
  Input,
  useFormControl,
} from "@mui/material";
import moment from "moment";
import Grid from "@mui/material/Grid";
import GoogleMapReact from "google-map-react";
import MuiTextField from "@mui/material/TextField";
import RoomIcon from "@mui/icons-material/Room";
import { Chat } from "../Chat";
import { useSearchParams } from "react-router-dom";

const PREFIX = "UseEmployee";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseShow = styled(Edit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

export const UseEmployee = (props) => {
  const { data, loading, error } = useGetList("employee", {
    filter: {
      startDate: moment(props.record?.bookingDate).format(),
      endDate: moment(props.record?.bookingDate)
        .add(props.record?.totalEstimatedWorkMinutes - 1, "minutes")
        .format(),
      idProv: props.record?.provider._id,
      forAssign: true,
      isInBranch: props.record?.isInBranch,
      branch: props.record?.branch?._id,
      serviceCart: props.record?.serviceCart.map((e) => e.service?._id),
    },
  });
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  // console.log("record: ", props.record);
  // console.log("dataEmployee: ", data);
  return (
    <FormControl style={{ minWidth: 200 }}>
      {/* <InputLabel id="employee">Empleado Asignado</InputLabel> */}
      <MuiTextField
        className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense"
        autoWidth
        select
        onChange={(e) => {
          props.setEmployee(e.target.value);
        }}
        labelId="employee"
        children={data
          ?.filter((e) => e.state)
          .map((e) => {
            return (
              <MenuItem value={e}>{e.first_name + " " + e.last_name}</MenuItem>
            );
          })}
        label="Empleado Asignado"
      />
    </FormControl>
  );
};

export const Show = ({ ...props }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  let [employee, setEmployee] = useState(null);
  const { data, record } = useEditController(props);
  const [update, { loading }] = useUpdate();
  const [searchParams, setSearchParams] = useSearchParams();


  const confirmBooking = (withEmployee = true) =>
    update(
      "booking",
      {
        id: record._id,
        data: {
          employee: withEmployee
            ? {
                _id: employee._id,
                fullName: employee.first_name + " " + employee.last_name,
                phone: employee.phone,
                photoURL: employee.picture,
                pushToken: employee.pushToken,
                CI: employee.CI_NIT,
                location: [],
              }
            : null,
        },
        meta: { customAction: "confirm" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("Empleado asignado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al asignar empleado: ${error}`, "warning"),
      }
    );

  const assignEmployee = (e) => {
    e.preventDefault();
    if (employee) {
      return confirmBooking();
    } else {
      return notify("Asignar al empleado.", "warning");
    }
  };

  const confirmWithoutEmployee = (e) => {
    e.preventDefault();
    return confirmBooking(false);
  };

  const cancel = () =>
    update(
      "booking",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toCancelledByProvider" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("Reserva cancelada con éxito");
        },
        onFailure: (error) =>
          notify(`Error al cancelar reserva: ${error}`, "warning"),
      }
    );

  const submitCancel = (e) => {
    e.preventDefault();
    cancel();
  };

  const finish = () =>
    update(
      "booking",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toFinished" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("servicio finalizado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al finalizar servicio: ${error}`, "warning"),
      }
    );

  const submitFinish = (e) => {
    e.preventDefault();
    finish();
  };

  const start = () =>
    update(
      "booking",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toProcessing" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("servicio iniciado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al iniciar servicio: ${error}`, "warning"),
      }
    );

  const submitStart = (e) => {
    e.preventDefault();
    start();
  };

  const pay = () =>
    update(
      "booking",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toPaid" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("servicio pagado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al pagar servicio: ${error}`, "warning"),
      }
    );

  const submitPaid = (e) => {
    e.preventDefault();
    pay();
  };

  const quote = (data) =>
    update(
      "booking",
      {
        id: record._id,
        data: {
          serviceCart: data.serviceCart,
          employee: data.employee,
        },
        meta: { customAction: "toQuoteSentAndConfirm" },
      },
      {
        onSuccess: (data) => {
          const redirectParam = searchParams.get("redirect")
            ? searchParams.get("redirect") + "?date=" + data.bookingDate
            : null;
          redirect(redirectParam ? `/${redirectParam}` : "/booking");
          notify("servicio cotizado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al cotizar servicio: ${error}`, "warning"),
      }
    );

  const submitQuote = (data) => {
    data.serviceCart = data.serviceCart.map((item) => {
      item.price = item.quantity * item.service.unitPrice;
      return item;
    });
    quote(data);
  };

  return (
    //  <StyledBaseShow {...props}>
     <Edit {...props}> 
      <SimpleForm
        onSubmit={submitQuote}
        toolbar={
          record && record.status == "Pendiente de cotizacion" ? (
            <SaveButton
              // variant="contained"
              // color="primary"
              // size="small"
              // type="button"
              label="Enviar cotización y confirmar"
              alwaysEnable

              // onClick={submitQuote}
            >
              {/* Enviar cotización */}
            </SaveButton>
          ) : (
            <></>
          )
        }
        // style={{ alignItems: "normal" }}
        // className=""
      >
        <TabbedShowLayout>
          <Tab label="Información de la Reserva">
            <TextField source="status" label="Estado de la reserva" />
            <DateField
              showTime
              source="bookingDate"
              label="Fecha de Inicio"
              locales="es-ES"
            />

            {record &&
              record.serviceCart &&
              (record?.status === "Pendiente de cotizacion" ? (
                <ArrayInput source="serviceCart" label="Servicios Adquiridos">
                  <SimpleFormIterator
                    disableReordering
                    inline
                    disableAdd
                    disableClear
                    disableRemove
                  >
                    <TextInput source="service.name" label="Nombre" disabled />
                    <NumberInput source="quantity" label="Cantidad" disabled />

                    {record.serviceCart.some(
                      ({ service }) => service.variablePrice
                    ) ? (
                      <NumberInput
                        source="service.unitPrice"
                        label="Precio Unitario"
                        min={0}
                      />
                    ) : (
                      <NumberField
                        source="service.unitPrice"
                        label="Precio Unitario"
                      />
                    )}

                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource }) => {
                        return (
                          <NumberInput
                            source="price"
                            label="Subtotal (Bs)"
                            disabled
                            format={() =>
                              scopedFormData.quantity *
                              scopedFormData.service.unitPrice
                            }
                          />
                        );
                      }}
                    </FormDataConsumer>

                    <TextInput
                      label="Tiempo Estimado Unitario"
                      textAlign="center"
                      disabled
                      source="service.unitEstimatedWorkMinutes"
                      format={toHoursAndMinutes}
                    />
                    <TextInput
                      label="Tiempo Estimado Subtotal"
                      textAlign="center"
                      disabled
                      source="estimatedWorkMinutes"
                      format={toHoursAndMinutes}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              ) : (
                <ArrayField source="serviceCart" label="Servicios Adquiridos">
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="service.name" label="Nombre" />
                    <NumberField source="quantity" label="Cantidad" />
                    <NumberField
                      source="service.unitPrice"
                      label="Precio Unitario"
                    />
                    <NumberField source="price" label="Subtotal (Bs)" />
                    <FunctionField
                      label="Tiempo Estimado Unitario"
                      textAlign="center"
                      render={({ service }) =>
                        toHoursAndMinutes(service.unitEstimatedWorkMinutes)
                      }
                    />
                    <FunctionField
                      label="Tiempo Estimado Subtotal"
                      textAlign="center"
                      render={({ estimatedWorkMinutes }) =>
                        toHoursAndMinutes(estimatedWorkMinutes)
                      }
                    />
                  </Datagrid>
                </ArrayField>
              ))}

            {record?.status === "Pendiente de cotizacion" ? (
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  return (
                    <NumberInput
                      source="paymentInfo.totalPrice"
                      label="Precio Total (Bs)"
                      disabled
                      format={(v) =>
                        formData.serviceCart.reduce(
                          (acc, { quantity, service }) =>
                            acc + quantity * service.unitPrice,
                          0
                        )
                      }
                      parse={(v) =>
                        formData.serviceCart.reduce(
                          (acc, { quantity, service }) =>
                            acc + quantity * service.unitPrice,
                          0
                        )
                      }
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <TextField
                source="paymentInfo.totalPrice"
                label="Precio Total (Bs)"
              />
            )}
            <TextField
              source="paymentInfo.paymentMethod"
              label="Método de Pago"
            />
            <FunctionField
              label="Tiempo Estimado Total"
              render={({ totalEstimatedWorkMinutes }) =>
                toHoursAndMinutes(totalEstimatedWorkMinutes)
              }
            />
            <TextField source="paymentInfo.status" label="Estado de Pago" />
            {record?.feedbackInfo?.rating && (
              <TextField source="feedbackInfo.rating" label="Rating" />
            )}
            {record?.feedbackInfo?.comment && (
              <TextField source="feedbackInfo.comment" label="Comentario" />
            )}
            {record && (
              <BooleanField
                source="isInBranch"
                label="¿Servicio en Sucursal?"
              />
            )}
            {record?.branch && (
              <TextField source="branch.name" label="Sucursal" />
            )}
            <Box sx={{ "& button": { m: 1 } }}>
              {record?.employee?.fullName && (
                <h4>Empleado asignado: {record.employee?.fullName || ""}</h4>
              )}
              {record &&
                (record.status === "Pendiente" ||
                  record.status === "Confirmado") && (
                  <UseEmployee setEmployee={setEmployee} record={record} />
                )}
              <br />
              {record &&
                (record.status === "Pendiente" ||
                  record.status === "Confirmado") && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={assignEmployee}
                    size="small"
                  >
                    <>Asignar Empleado</>
                  </Button>
                )}
              <br />
              {record && record.status === "Pendiente" && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={confirmWithoutEmployee}
                >
                  <>Aceptar reserva sin asignar empleado</>
                </Button>
              )}
              <br />
              {record &&
                (record.status === "Pendiente" ||
                  record.status === "Confirmado" ||
                  record.status === "Pendiente de cotizacion" ||
                  record.status === "Cotizacion enviada") &&
                record.createdBy === record.provider._id && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={submitCancel}
                    >
                      <>Cancelar Reserva</>
                    </Button>
                    <br />
                  </>
                )}

              {record && record.status === "En proceso" && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={submitFinish}
                >
                  Finalizar
                </Button>
              )}

              {record &&
                record.status === "Confirmado" &&
                record.employee?._id && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={submitStart}
                  >
                    Iniciar Servicio
                  </Button>
                )}

              {record &&
                record.status === "Finalizado" &&
                record.paymentInfo.status === "Pendiente" &&
                record.employee?._id && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={submitPaid}
                  >
                    Servicio Pagado
                  </Button>
                )}

              <br />
            </Box>
            {record?.notes && <TextField source="notes" label="Detalles" />}
          </Tab>
          <Tab label="Información del Cliente">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 style={{ textAlign: "center" }}>
                  {record?.customer.fullName}
                </h2>
              </Grid>

              <Grid item xs={12} md={record?.directionDestination ? 6 : 12}>
                {!record?.customer.address && (
                  <p>No se ha encontrado dirección para este cliente</p>
                )}
                {!record?.isInBranch && record?.customer.address && (
                  <>
                    <h3>Dirección del cliente</h3>
                    <div style={{ height: "50vh", width: "100%" }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                        }}
                        defaultCenter={{
                          lat: record?.customer.address?.coordinates.latitude,
                          lng: record?.customer.address?.coordinates.longitude,
                        }}
                        defaultZoom={15}
                      >
                        <RoomIcon
                          lat={record?.customer.address?.coordinates?.latitude}
                          lng={record?.customer.address?.coordinates?.longitude}
                          style={{
                            fontSize: 40,
                            position: "relative",
                            top: -40,
                            left: -20,
                          }}
                        />
                      </GoogleMapReact>
                    </div>
                    <span className="MuiTypography-root MuiTypography-body2">
                      {record?.customer.address.name}
                      {record?.customer.address.street} #
                      {record?.customer.address.house},{" "}
                      {record?.customer.address.reference}
                    </span>
                  </>
                )}
                {record?.billingInfo?.businessName && (
                  <div>
                    <h3>Datos de Facturación</h3>
                    <span className="MuiTypography-root MuiTypography-body2">
                      {record?.billingInfo.businessName}, NIT:
                      {record?.billingInfo.NIT}
                    </span>
                  </div>
                )}
              </Grid>
            </Grid>
            <br></br>
          </Tab>
          {(record?.status === "Confirmado" ||
            record?.status === "En proceso" ||
            record?.status === "Pendiente de cotizacion" || 
            record?.status === "Cotizacion enviada"
          ) && (
            <Tab label="Chat">
              <div style={{ display: "flex", height: 500 }}>
                <Chat
                  orderId={record._id}
                  orderType="booking"
                  userId={record.provider._id}
                />
              </div>
            </Tab>
          )}
        </TabbedShowLayout>
      </SimpleForm>
      </Edit> 
    // </StyledBaseShow>
  );
};
