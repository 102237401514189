import React, { useCallback, useEffect, useState } from "react";
import {
  Create,
  required,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  PasswordInput,
  NumberInput,
  useGetList,
  usePermissions,
  SimpleShowLayout,
  useChoicesContext,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  TopToolbar,
  FilterButton,
  CreateButton,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  DeleteButton,
  useListContext,
  Button,
  useDataProvider,
} from "react-admin";
import { Loading, Error } from "react-admin";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { getFinalFor, getInitialFor } from "./availability";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import useExport from "./UseExcelExport";
import ExportButton from "./ExcelExportButton";

const PREFIX = "AvailabilityEdit";
const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const UseDeliverySetting = () => {
  const { data, isLoading, error } = useGetList("deliverysetting");
  let [vehicle, setVehicle] = useState("");
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div>
      <SelectInput
        onChange={(e) => {
          setVehicle(e.target.value);
        }}
        source="deliveryVehicle"
        label="Vehículo"
        choices={data.map((e) => {
          let obj = JSON.stringify({ _id: e._id, title: e.title });
          return { id: obj, name: e.title };
        })}
      />
    </div>
  );
};

const EmployeeFilter = [
    <TextInput label="Nombre" source="first_name" />, 
    <TextInput label="Apellido" source="last_name" />
];

function formatLogo(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

const ListActions = (props) => {
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const providerId = permissions === "admin-sucursal" ? profile.employer._id : profile._id;
  const { getExportData, exportData, loading, setExportData} = useExport(`employeesAll/${providerId}`);
  const [openModal, setOpenModal] = useState(false);
  const { filterValues } = useListContext();
  filterValues.branchId = profile?.branch?._id;
  console.log(filterValues)
  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData(filterValues);
      setOpenModal(false);
    }
  };

  const handleClearData = () => {
    setExportData([]);
  };
  const { className, basePath } = props;

  const columns = [
    { label: "Nombre", source: "first_name" },
    { label: "Apellido", source: "last_name" },
    { label: "Email", source: "email" },
    { label: "Teléfono", source: "phone" },
    { label: "Estado", source: "state", transform: (value) => (value ? "habilitado" : "no habilitado") },
    { label: "Método", source: "serviceMethod" },
    { label: "Sucursal", source: "branch.name" },
  ];

  return (
    <TopToolbar
      className={className}
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={EmployeeFilter} />
        <CreateButton basePath={basePath} />
      </div>
      <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="empleados.xlsx" loading={loading} clearData={handleClearData}/>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

const TotalFooter = () => {
  const { total } = useListContext(); // Obtener el total del contexto del listado

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" padding="16px">
      <Typography variant="body1">
        {`Total de empleados: ${total ?? 0}`}
      </Typography>
    </Box>
  );
};

export const EmployeeList = (props) => {
  const [employees, setEmployees] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const token = localStorage.getItem("token");
  const permissions = profile?.role;
  const providerId = permissions === "admin-sucursal" ? profile.employer._id : profile._id;

  const branchId =  profile?.branch?._id;
  const branchUrl = branchId !== undefined?`?branchId=${branchId ?? ''}`:''

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setIsLoading(true);
        console.log('entro')
        const response = await fetch(`${process.env.REACT_APP_API_URL}/employeesAll/${providerId}${branchUrl}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        
        if (response.ok) {
          const formattedEmployees = data.data.map((employee) => ({
            ...employee,
            id: employee._id, // Utiliza `_id` como `id`
          }));
          setEmployees(formattedEmployees);
          setTotal(data.totalDocs); // Total de empleados
        } else {
          console.error("Error fetching employees:", data.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployees();
  }, [providerId, branchId, token, branchUrl]);

  return (
    <List
      actions={<ListActions />}
      {...props}
      // resource= {`employeesAll/${providerId}`}
      // filter={{
      //   branchId: profile?.branch?._id,
      // }}
      pagination={false}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false} data={employees}>
        <TextField source="first_name" label="Nombre" />
        <TextField source="last_name" label="Apellido" />
        <ImageField source="picture" label="Foto" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Teléfono" />
        <BooleanField source="state" label="Estado" />
        {permissions === "admin" && <TextField source="deliveryVehicle.title" label="Vehículo" />}
        <TextField source="serviceMethod" label="Método" />
        {permissions !== "admin-sucursal" && <TextField source="branch.name" label="Sucursal" />}
      </Datagrid>
      <Box display="flex" justifyContent="flex-end" alignItems="center" padding="16px">
            <Typography variant="body1">
              {`Total de empleados: ${total}`}
            </Typography>
          </Box>
    </List>
  );
};

const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/employees");
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este empleado? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};

export const EmployeeEdit = (props) => {
  const [services, setServices] = useState([]);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  useEffect(() => {
    dataProvider
      .getList("service", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        // Transformar los datos para el SelectArrayInput
        const transformedServices = data.map((service) => ({
          ...service,
          id: service._id, // Asegurarse de que el id es utilizado por react-admin
        }));
        setServices(transformedServices);
      })
      .catch((error) => {
        console.error("Error al cargar los servicios:", error);
      });
  }, [dataProvider]);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ImageInput format={formatLogo} validate={[required()]} source="picture" label="Foto de Empleado" accept=".jpeg,.jpg,.png,.jfif" placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput validate={[required()]} source="last_name" label="Apellido" />
        <BooleanInput validate={[required()]} source="state" label="Estado" />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <SimpleShowLayout>
          <TextField validate={[required()]} source="email" />
          {permissions === "admin" && <TextField source="deliveryVehicle.title" label="Vehículo" />}
        </SimpleShowLayout>
        <ServiceSelection />
        {/* <SelectInput
          validate={[required()]}
          source="serviceMethod"
          label="Método de Servicio"
          choices={[
            { id: "En sucursal", name: "En sucursal" },
            { id: "A domicilio", name: "A domicilio" },
            { id: "Ambos", name: "Ambos" },
          ]}
        /> */}

        <ReferenceArrayInput source="availableServices" reference="service" queryOptions={{ filter: { method: "serviceMethod" } }}>
          <SelectArrayInput label="Servicios Disponibles" />
        </ReferenceArrayInput>

        <ArrayInput source="servicesCommissions" label="Comisión de Servicios">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData, formData }) => (
                scopedFormData && (
                  <SelectInput
                    source={getSource('service')}
                    label="Servicio"
                    choices={services.filter(service => formData.availableServices.includes(service.id))}
                    optionText="name"
                    optionValue="id"
                  />
                )
              )}
            </FormDataConsumer>
            <NumberInput source="percentage" label="Comisión (%)" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>{({ formData, scopedFormData, getSource, ...rest }) => <SelectInput validate={[required()]} source={getSource("startHour")} label="Hora de Inicio" choices={getInitialFor(scopedFormData?.endHour)} />}</FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>{({ formData, scopedFormData, getSource, ...rest }) => <SelectInput validate={[required()]} source={getSource("endHour")} label="Hora Final" choices={getFinalFor(scopedFormData?.startHour)} formClassName={classes.inlineBlock} />}</FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeCreate = (props) => {
   const [services, setServices] = useState([]);
   const dataProvider = useDataProvider();
   const { permissions } = usePermissions();

   useEffect(() => {
     dataProvider
       .getList("service", {
         pagination: { page: 1, perPage: 100 },
         sort: { field: "name", order: "ASC" },
         filter: {},
       })
       .then(({ data }) => {         
         const transformedServices = data.map((service) => ({
           ...service,
           id: service._id,
         }));
         setServices(transformedServices);
       })
       .catch((error) => {
         console.error("Error al cargar los servicios:", error);
       });
   }, [dataProvider]);

  return (
    <Create {...props}>
      <SimpleForm>
        <ImageInput validate={[required()]} source="picture" label="Foto de Empleado" accept=".jpeg,.jpg,.png,.jfif" placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput validate={[required()]} source="last_name" label="Apellido" />

        <TextInput validate={[required()]} source="email" label="Email" />
        <PasswordInput validate={[required()]} source="password" label="Contraseña" />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <NumberInput validate={[required()]} source="CI_NIT" label="CI" />
        {permissions === "admin" && <UseDeliverySetting />}
        <ServiceSelection />

        <ReferenceArrayInput source="availableServices" reference="service" queryOptions={{ filter: { method: "serviceMethod" } }}>
          <SelectArrayInput label="Servicios Disponibles" />
        </ReferenceArrayInput>

        <ArrayInput source="servicesCommissions" label="Comisión de Servicios">
          <SimpleFormIterator>
            <FormDataConsumer>{({ getSource, scopedFormData, formData }) => scopedFormData && <SelectInput source={getSource("service")} label="Servicio" choices={services.filter((service) => formData.availableServices.includes(service.id))} optionText="name" optionValue="id" />}</FormDataConsumer>
            <NumberInput source="percentage" label="Comisión (%)" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>{({ formData, scopedFormData, getSource, ...rest }) => <SelectInput validate={[required()]} source={getSource("startHour")} label="Hora de Inicio" choices={getInitialFor(scopedFormData?.endHour)} />}</FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>{({ formData, scopedFormData, getSource, ...rest }) => <SelectInput validate={[required()]} source={getSource("endHour")} label="Hora Final" choices={getFinalFor(scopedFormData?.startHour)} formClassName={classes.inlineBlock} />}</FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const SelectBranchInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const serviceMethod = useFormContext().watch("serviceMethod");
  return serviceMethod && serviceMethod !== "A domicilio" ? (
    <SelectInput
      {...props}
      label="Sucursal"
      optionText={(value) => {
        return value.name;
      }}
      optionValue="_id"
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      validate={[required()]}
    />
  ) : (
    <React.Fragment />
  );
};

const ServiceSelection = () => {
  const { permissions } = usePermissions();
  const form = useWatch("serviceMethod");
  const { setValue } = useFormContext();

  React.useEffect(() => {
    console.log("method", form.serviceMethod);
  }, [form]);

  React.useEffect(() => {  
    const profile = JSON.parse(localStorage.getItem("profile"));

    if (profile?.role === "admin-sucursal" && profile?.branch) {
      setValue("branch", profile.branch);
   }
  }, [setValue]);

  const [selectedServiceId, setSelectedServiceId] = useState("");

  // Efecto para registrar cambios
  // useEffect(() => {
    console.log("Servicio seleccionado actualmente: ", selectedServiceId);
  // }, [selectedServiceId]);

  return (
    <>
      <SelectInput
        validate={[required()]}
        source="serviceMethod"
        label="Método de Servicio"
        choices={[
          { id: "En sucursal", name: "En sucursal" },
          { id: "A domicilio", name: "A domicilio" },
          { id: "Ambos", name: "Ambos" },
        ]}
      />
      {permissions !== "admin-sucursal" && (
        <ReferenceInput
          label="Sucursal"
          source="branch"
          reference="enterprise/branches"
          filter={{
            status: true,
          }}
        >
          <SelectBranchInput />
        </ReferenceInput>
      )}      
    </>
  );
};
